@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  simpleParallax
// ==================================================
.simpleParallax {
  transform: scale(0.835);
  position: relative;
  top: -5.3vw;
  overflow: visible !important;
}
